import { Box, Typography } from '@mui/material';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import { PCIComplianceAcknowledgmentForm } from 'module/documents/data';

export const PCIComplianceForm = () => {
	const { formik, isEmployer, selectedEmployee } = useDocumentContext();


	const handleSubmit = () => {
		console.log();
	};

	const name = selectedEmployee.user.first_name + ' ' + selectedEmployee.user.last_name;

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{PCIComplianceAcknowledgmentForm.title}
				</Typography>

				<Typography variant="body1">
					This certificate is for Michigan income tax withholding purposes only.
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection>
					<FormAlert severity="info">
						I <strong>{name}</strong>, have received, reviewed and understand the Employee Training
						Documents for PCI Compliance for [Company Name]. I agree to observe the terms and
						conditions of this training. I understand that failure to comply may result in
						disciplinary action and could jeopardize my organizations PCI-DSS compliance standing.
					</FormAlert>

					<FormAlert severity="info">Documents reviewed:</FormAlert>

					<DynamicFormComponent>
						{PCIComplianceAcknowledgmentForm.fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
			</Box>

			<Box paddingY="24px">
				<SignAndSubmit onSubmit={handleSubmit} formData={PCIComplianceAcknowledgmentForm} />
			</Box>

			<FormGroupSection show={isEmployer}>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields fieldValues={['employer_title']} />
				</FormGroupSection>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Employeer sign
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
