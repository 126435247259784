import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, Box, List, ListItem, Typography } from '@mui/material';
import {
	DelayButton,
	FormAlert,
	FormGroupSection,
	SignAndSubmit,
	StyledAccordionSummary,
} from 'module/documents/components/components';
import DynamicFormComponent, {
	FieldRenderer,
	GridWrapper,
} from 'module/documents/components/formTabs/DynamicFormComponent';
import { EmployerFields } from 'module/documents/components/formTabs/EmployerFields';
import { useDocumentContext } from 'module/documents/context';
import { CommonFieldsFormIndexed, MIW4FormData, MIW4FormDataIndexed } from 'module/documents/data';
import { filterFields } from 'module/documents/utils';

const step1Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['first_name', 'middle_initial', 'last_name', 'date_of_birth', 'phone'],
});

const step2Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['address', 'city', 'state', 'zip_code'],
});

const step3Fields = filterFields(CommonFieldsFormIndexed, {
	include: ['ssn', 'drivers_license_number'],
});

const newFields = filterFields(MIW4FormDataIndexed, {
	include: ['personal_exceptions', 'deduct_amount'],
});

const wageFields = filterFields(MIW4FormDataIndexed, {
	include: [
		'tax_not_expected',
		'wages_exempt',
		'wages_exempt_explanation',
		'home_residence_zone',
		'home_residence_zone_explanation',
	],
});

const ExemptionInfo = () => {
	return (
		<List>
			<ListItem>
				Personal and dependency exemptions. The number of exemptions claimed here may not exceed the
				number of exemptions you are entitled to claim on a Michigan Individual Income Tax Return
				(Form MI-1040). Dependents include qualifying children and qualifying relatives under the
				Internal Revenue Code, even if your AGI exceeds the limits to claim federal tax credits for
				them.
			</ListItem>
			<ListItem>
				- Do not claim the same exemptions more than once or tax will be under-withheld.
				Specifically, do not claim:
			</ListItem>
			<ListItem>
				- Your personal exemption if someone else will claim you as their dependent.
			</ListItem>
			<ListItem>- Your personal exemption with more than one employer at a time.</ListItem>
			<ListItem>- Your spouse’s personal exemption if they claim it with their employer.</ListItem>
			<ListItem>
				- Your dependency exemptions if someone else (for example, your spouse) is claiming them
				with their employer.
			</ListItem>
		</List>
	);
};

export const Section8AExplanation = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">8a instructions</Typography>
		</StyledAccordionSummary>

		<AccordionDetails>
			<List>
				<ListItem>Your employment is intermittent, temporary, or less than full time</ListItem>
				<ListItem>
					Your personal and dependency exemptions exceed your annual taxable compensation;
				</ListItem>
				<ListItem>You claimed exemption from federal withholding</ListItem>
				<ListItem>
					You did not incur a Michigan income tax liability for the previous year.
				</ListItem>
			</List>
		</AccordionDetails>
	</Accordion>
);

export const Section8BExplanation = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">8b instructions</Typography>
		</StyledAccordionSummary>

		<AccordionDetails>
			<Typography variant="body1">
				8b: Reasons wages might be exempt from withholding include:
			</Typography>
			<List>
				<ListItem>
					You are a nonresident spouse of military personnel stationed in Michigan.
				</ListItem>
				<ListItem>
					You are a resident of one of the following reciprocal states while working in Michigan:
					Illinois, Indiana, Kentucky, Minnesota, Ohio, or Wisconsin.
				</ListItem>
				<ListItem>
					You are a member of a Native American tribe that has a tax agreement with the State of
					Michigan and whose principal place of residence is within the designated agreement area.
				</ListItem>
				<ListItem>
					You are an enrolled member of a federallyrecognized tribe that does not have a tax
					agreement with the State of Michigan, you reside within that tribe’s Indian Country (as
					defined in 18 USC 1151), and compensation from this job will be earned within that Indian
					Country.
				</ListItem>
			</List>
		</AccordionDetails>
	</Accordion>
);

export const Section8CExplanation = () => (
	<Accordion>
		<StyledAccordionSummary
			expandIcon={<ExpandMoreIcon />}
			aria-controls="panel1-content"
			id="panel1-header"
		>
			<Typography variant="body1">8c instructions</Typography>
		</StyledAccordionSummary>

		<AccordionDetails>
			<Typography variant="body1">
				8c: For questions about Renaissance Zones, contact your local assessor’s office.
			</Typography>
		</AccordionDetails>
	</Accordion>
);

export const MIW4 = () => {
	const { formik, isEmployer } = useDocumentContext();

	const handleSubmit = () => {
		console.log();
	};

	return (
		<>
			<Box mb={2}>
				<Typography variant="h4" mb="24px">
					{MIW4FormData.title}
				</Typography>

				<Typography variant="body1">
					This certificate is for Michigan income tax withholding purposes only.
				</Typography>
			</Box>

			<Box display="flex" flexDirection="column" gap={2}>
				<FormGroupSection title="Enter Personal Information">
					<DynamicFormComponent>
						{step1Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent>
						{step2Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent>
						{step3Fields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					<DynamicFormComponent>
						<GridWrapper field={MIW4FormDataIndexed['new_employee']} index={0}>
							<FieldRenderer field={MIW4FormDataIndexed['new_employee']} formik={formik} />
						</GridWrapper>

						{formik.values.new_employee && (
							<GridWrapper field={MIW4FormDataIndexed['first_date_employment']} index={0}>
								<FieldRenderer
									field={MIW4FormDataIndexed['first_date_employment']}
									formik={formik}
								/>
							</GridWrapper>
						)}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection title="Withholding Exemptions">
					<FormAlert severit="info">
						<ExemptionInfo />
					</FormAlert>

					<DynamicFormComponent marginTop="12px">
						<Typography variant="body1">
							I claim exemption from withholding because (see instructions):
						</Typography>
						{newFields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>
				</FormGroupSection>
				<FormGroupSection>
					{/* <FormAlert severit="info">
						<ExemptionInfo />
					</FormAlert> */}

					<DynamicFormComponent marginTop="12px">
						<Typography variant="h6">
							I claim exemption from withholding because (see instructions):
						</Typography>
						{wageFields.map((field, index) => (
							<GridWrapper key={index} field={field} index={index}>
								<FieldRenderer field={field} formik={formik} />
							</GridWrapper>
						))}
					</DynamicFormComponent>

					<Box paddingY="12px">
						<Section8AExplanation />
					</Box>
					<Box paddingY="12px">
						<Section8BExplanation />
					</Box>
					<Box paddingY="12px">
						<Section8CExplanation />
					</Box>
				</FormGroupSection>
			</Box>
			<SignAndSubmit onSubmit={handleSubmit} formData={MIW4FormData} />
			<FormGroupSection>
				<FormGroupSection
					title="Employer Information"
					isEmployer={true}
					marginTop="24px"
					marginBottom="12px"
				>
					<EmployerFields
						fieldValues={[
							'employer_title',
							'employer_business_name',
							'employer_business_address',
							'employer_city',
							'employer_state',
							'employer_zip_code',
							'employer_ein',
						]}
					/>
				</FormGroupSection>
				<FormAlert>
					Under penalties of perjury, I declare that this certificate, to the best of my knowledge
					and belief, is true, correct, and complete.
				</FormAlert>
				<Box paddingY="8px">
					<DelayButton
						variant="contained"
						onClick={() => {
							formik.setFieldValue('signature_approved', true);
						}}
						style={{ width: 'max-content' }}
					>
						Sign employee certification
					</DelayButton>
				</Box>
			</FormGroupSection>
		</>
	);
};
