import { Box } from '@mui/material';
import { FormGroupSection } from 'module/documents/components/components';
import { useDocumentContext } from 'module/documents/context';
import { useEmployeeFormDetails } from 'module/documents/hooks';
import { WorkspaceUserAutocomplete } from 'module/documents/tabs/HumanResource/components';

import { FC } from 'react';
import { FullLoader } from 'shared/components/Loader/FullLoader';

const HumanResourceTab: FC = () => {
	const { isEmployer } = useDocumentContext();
	const { isLoading } = useEmployeeFormDetails({}, !isEmployer);

	if (isLoading) {
		return <FullLoader />;
	}

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<FormGroupSection>
				{/* {items?.map((details) => {
					return (
						<div key={details.id}>
							<Typography as="body1">
								{details.employee.first_name} {details.employee.last_name} {details.id}
							</Typography>
						</div>
					);
				})} */}
				<WorkspaceUserAutocomplete />
			</FormGroupSection>
		</Box>
	);
};

export default HumanResourceTab;
