/* eslint-disable @typescript-eslint/no-unused-vars */
import { getMe } from 'core/API/broadcast';
import {
	CommonFormResponseFields,
	DocsEmployeeFormDetail,
	DocsEmployeeFormI9,
} from 'core/API/documents';
import { useWorkspaceResources } from 'core/hooks/broadcast';
import { IUsers } from 'core/interfaces/userInterface';
import { FormikState } from 'formik';
import { FormField, FormGroup } from 'module/documents/data';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { UserMe } from 'types';

/**
 * @deprecated Index the data instead
 */
export const allowFormData = (data: FormGroup, fieldsToFilted: Array<string>) => {
	return {
		title: data.title,
		fields: data.fields.filter((field) => fieldsToFilted.includes(field.value)),
	};
};

/**
 * @deprecated Index the data instead
 */
export const rejectFormData = (data: FormGroup, fieldsToFilted: Array<string>) => {
	return {
		title: data.title,
		fields: data.fields.filter((field) => !fieldsToFilted.includes(field.value)),
	};
};

export const getFormField = (data: FormGroup, name: string) => {
	const field = data.fields.find((field) => field.value === name);

	return field;
};

// create a function that takes a number and returns USD currency format
export const formatCurrency = (num: number): string => {
	return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

/**
 * Validates the form by checking if all non-optional fields are filled.
 *
 * @param {FormikProps<any>} formik - The Formik object containing form state and helpers.
 * @param {FormGroup} formData - The form data containing fields definitions.
 *
 * @returns {boolean} - Returns true if the form is valid, otherwise false.
 */
export const validateForm = (
	formik: FormikState<any>,
	formData: FormGroup
): { valid: boolean; missingFields: string[] } => {
	const missingFields: string[] = [];

	for (const field of formData.fields) {
		if (!field.isOptional && !formik.values[field.value]) {
			missingFields.push(field.label);
		}
	}

	const isValid = missingFields.length === 0;

	console.debug('Form is valid:', isValid);
	return {
		valid: isValid,
		missingFields,
	};
};

export const useFieldFilter = (data: any, include: string[], exclude: string[] = []) => {
	const indexedFields = useMemo(() => {
		const fieldMap: Record<string, any> = {};
		data.fields.forEach((field: any) => {
			fieldMap[field.value] = field;
		});
		return fieldMap;
	}, [data]);

	const filteredFields = useMemo(() => {
		return include
			.filter((value) => !exclude.includes(value))
			.map((value) => indexedFields[value])
			.filter(Boolean);
	}, [indexedFields, include, exclude]);

	return filteredFields;
};

export interface FieldFilterOptions {
	include?: string[];
	exclude?: string[];
}

export const filterFields = (
	formDataIndexed: Record<string, FormField>,
	options: FieldFilterOptions
): FormField[] => {
	const { include = [], exclude = [] } = options;

	if (include.length !== 0) {
		return include
			.filter((value) => !exclude.includes(value))
			.map((value) => formDataIndexed[value])
			.filter(Boolean);
	}

	if (exclude.length !== 0) {
		return Object.values(formDataIndexed).filter((field) => !exclude.includes(field.value));
	}
};

export const indexFieldsByValue = (formData: FormGroup): Record<string, FormField> => {
	return formData.fields.reduce(
		(acc, field) => {
			if (field.value) {
				acc[field.value] = field;
			}
			return acc;
		},
		{} as Record<string, FormField>
	);
};

export const useWorkplaceUser = () => {
	const { data: usersData } = useWorkspaceResources();

	// Fetch the logged-in user info (getMe)
	const { data: userInfo, isLoading } = useQuery<UserMe>('me', getMe, {
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});

	// Find the user in usersData based on the ID from getMe
	const foundUser = useMemo(() => {
		return usersData?.find((user: IUsers) => {
			return user.user.id === userInfo?.id;
		});
	}, [usersData, userInfo?.id]);

	return { data: foundUser, isLoading };
};

export const dedupItems = (items: CommonFormResponseFields[]) => {
	const reduced: Record<string, CommonFormResponseFields> = items.reduce((acc, iter) => {
		acc[iter.employee.email] = iter;
		return acc;
	}, {});

	return Object.values(reduced);
};

/**
 * @deprecated Index the data instead
 */

export const getUserDetailByEmail = (details: any[], email: string) => {
	return details?.find((user) => user.employee.email === email) || null;
};

export const getUserDetailById = (details: any[], id: string) => {
	return details?.find((user) => user.employee.id === id) || null;
};

export const removeCommonFormFields = (formData: CommonFormResponseFields) => {
	if (!formData) {
		return {};
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { employee, workplace, id, identifier, author, ...rest } = formData;
	return { ...rest, ...employee };
};

export const pruneEmployeFormDetails = (param: DocsEmployeeFormDetail) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { employee, workplace, id, identifier, author, ...rest } = param;
	return {
		...rest,
		...employee,
		employee_signature: param.employee_signature_file?.id,
		internal_signature_file: param.employee_signature_file.path,
	};
};

export const pruneI9Data = (param: DocsEmployeeFormI9) => {
	const {
		employee,
		workplace,
		id,
		identifier,
		author,
		employee_form_i9_supplement,
		employee_form_i9_supplement_translator,
		...rest
	} = param;
	return {
		...rest,
		...employee,
	};
};

export const sanitizeAPIFormData = (formData) => {
	const sanitizedData = { ...formData }; // Create a copy to avoid modifying original data

	// Convert dates to Date objects
	sanitizedData.signature_date = new Date(sanitizedData.signature_date);
	sanitizedData.preparer_signature_date = new Date(sanitizedData.preparer_signature_date);
	sanitizedData.employer_signature_date = new Date(sanitizedData.employer_signature_date);
	sanitizedData.date_of_birth = new Date(sanitizedData.date_of_birth);
	sanitizedData.authorization_expiration_date = new Date(
		sanitizedData.authorization_expiration_date
	);

	// Convert filing_status to a number
	sanitizedData.filing_status = Number(sanitizedData.filing_status);

	return sanitizedData;
};
