import { CircularProgress, Typography, useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useDocumentContext } from 'module/documents/context';
import CommonFields from 'module/documents/tabs/CommonFields';
import { DirectDepositForm } from 'module/documents/tabs/DirectDepositForm';
import { EmployeeTippingNotice } from 'module/documents/tabs/EmployeeTippingNotice';
import { FoodReportAgreements } from 'module/documents/tabs/FoodReportAgreements';
import { FTEForm } from 'module/documents/tabs/FTEForm';
import HumanResourceTab from 'module/documents/tabs/HumanResourceTab';
import { IUnderstandForm } from 'module/documents/tabs/IUnderstandForm';
import { MIW4 } from 'module/documents/tabs/MIW4';
import { PCIComplianceForm } from 'module/documents/tabs/PCIComplianceForm';
import { W2Consent } from 'module/documents/tabs/W2Consent';
import { lazy, ReactNode, Suspense, SyntheticEvent, useMemo, useState } from 'react';

// lazy load I9Fields component
const W4Form = lazy(() => import('module/documents/tabs/W4Form'));
const I9Fields = lazy(() => import('module/documents/tabs/I9Fields'));
const I9SupplementAForm = lazy(() => import('module/documents/tabs/I9FieldsSupplementA'));
const I9SupplementBForm = lazy(() => import('module/documents/tabs/I9FieldsSupplementB'));

interface TabPanelProps extends BoxProps {
	children?: ReactNode;
	index: number;
	value: number;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...rest } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			minHeight="50vh"
			overflow="auto"
			p={3}
			flex="1"
			{...rest}
		>
			{value === index && children}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface Tabs {
	label: string;
	component: ReactNode;
	visible?: boolean;
	disabled?: boolean;
	helperText?: string;
}

// Extracted function to generate the tabs array
const generateTabs = (formikValues, isHumanResource, selectedEmployee): Array<Tabs> => {
	const disableAll = isHumanResource && !selectedEmployee;

	return [
		{ label: 'Select employee', component: <HumanResourceTab />, visible: isHumanResource },
		{ label: 'Common Fields', component: <CommonFields />, visible: true, disabled: disableAll },
		{ label: 'I-9 Form', component: <I9Fields />, visible: true, disabled: disableAll },
		{
			label: 'I-9 Supplement A',
			helperText: 'Enabled in I-9 Form #3a',
			component: <I9SupplementAForm />,
			disabled: disableAll || !formikValues.translator_assisted,
		},
		{
			label: 'I-9 Supplement B',
			component: <I9SupplementBForm />,
			visible: isHumanResource,
			disabled: disableAll || !formikValues.employee_rehire,
			helperText: 'Enabled in I-9 Form #3b',
		},
		{ label: 'W-4 Form', component: <W4Form />, visible: true, disabled: disableAll },
		{
			label: 'Notice to Tipped Employees',
			component: <EmployeeTippingNotice />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'MI-W4',
			component: <MIW4 />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'Food Report Agreements',
			component: <FoodReportAgreements />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'Electronic W2 consent form',
			component: <W2Consent />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'PCI Compliance Acknowledgment',
			component: <PCIComplianceForm />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'Direct Deposit Form',
			component: <DirectDepositForm />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'Full / Variable Time Form',
			component: <FTEForm />,
			visible: true,
			disabled: disableAll,
		},
		{
			label: 'I Understand Form',
			component: <IUnderstandForm />,
			visible: true,
			disabled: disableAll,
		},
	].filter((tab) => tab.visible !== false);
};

export function CompanyRegistrationStepper() {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const isAbove765 = useMediaQuery('(min-width:765px)');

	const { formik, isEmployer, selectedEmployee } = useDocumentContext();

	const handleFormTabChange = (event: SyntheticEvent, newValue: number) => {
		setActiveTabIndex(newValue);
	};

	const tabs = useMemo(
		() => {
			return generateTabs(formik.values, isEmployer, selectedEmployee);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.values?.translator_assisted, formik.values?.employee_rehire, selectedEmployee]
	);

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				flexDirection: isAbove765 ? 'row' : 'column',
				overflowY: 'auto',
				maxHeight: '75vh',
			}}
		>
			<Tabs
				variant="scrollable"
				scrollButtons={false}
				orientation={isAbove765 ? 'vertical' : 'horizontal'}
				value={activeTabIndex}
				textColor="secondary"
				indicatorColor="secondary"
				onChange={handleFormTabChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider', paddingLeft: '20px' }}
			>
				{tabs.map((tab, index) => (
					<Tab
						key={index}
						disabled={tab.disabled}
						label={
							<Box display="flex" flexDirection="column" position="relative" textAlign="left">
								{tab.label}
								{tab.helperText && tab.disabled && (
									<Typography fontSize="0.75rem" variant="subtitle1" sx={{ textTransform: 'none' }}>
										<strong>ⓘ</strong> {tab.helperText}
									</Typography>
								)}
							</Box>
						}
						{...a11yProps(index)}
					/>
				))}
			</Tabs>

			<Suspense
				fallback={
					<TabPanel
						value={100}
						index={100}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<CircularProgress />
					</TabPanel>
				}
			>
				{tabs.map((tab, index) => (
					<TabPanel key={index} value={activeTabIndex} index={index} sx={{ position: 'relative' }}>
						{tab.component}
					</TabPanel>
				))}
			</Suspense>
		</Box>
	);
}
