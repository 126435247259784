import {
	Participant,
	getBroadcastGroups,
	getDivisions,
	getMe,
	getOperatingUnits,
	getRoles,
	getWorkplaces,
} from 'core/API/broadcast';
import { Role } from 'core/API/roles';
import { GetParticipantsList, GetUsersList } from 'core/API/users';
import { Division } from 'core/interfaces/userInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useQuery } from 'react-query';
import { IPaginatedResponse, UserMe } from 'types';

export const useWorkspaceResources = (incomingWorkplace?: number) => {
	const { workplaceId } = useWorkplacesContext();

	return useQuery(
		['users', incomingWorkplace || workplaceId],
		() => GetUsersList(incomingWorkplace || workplaceId),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
		}
	);
};

export const useRoles = () => {
	return useQuery<IPaginatedResponse<Role>>('broadcast/roles', getRoles, {
		staleTime: Infinity,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};

export const useDivision = () => {
	return useQuery<IPaginatedResponse<Division>>('broadcast/divisions', getDivisions, {
		staleTime: Infinity,
		refetchIntervalInBackground: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};

export const useWorkplaces = (searchByOperatingUnits?: number[]) => {
	return useQuery<IPaginatedResponse<any>>(
		['broadcast/workplaces', searchByOperatingUnits],
		() => getWorkplaces(searchByOperatingUnits),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useBroadcastGroups = (workplaceId: number, limit: number = 20) => {
	return useQuery(
		['broadcast-groups', workplaceId, limit],
		() => getBroadcastGroups(workplaceId, limit),
		{
			select: (data) => {
				return data.items;
			},
			refetchInterval: 5000, // 5 seconds
			staleTime: Infinity,
			refetchIntervalInBackground: true,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useParticipants = () => {
	const { workplaceId } = useWorkplacesContext();

	return useQuery<Participant[]>(
		['broadcast/participants', workplaceId],
		() => GetParticipantsList(workplaceId),
		{
			staleTime: Infinity,
			enabled: !!workplaceId,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};

export const useOperatingUnits = (searchByDivision?: number[]) => {
	return useQuery<IPaginatedResponse<any>>(
		['broadcast/operating-units', searchByDivision],
		() => getOperatingUnits(searchByDivision),
		{
			staleTime: Infinity,
			refetchIntervalInBackground: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);
};
// Custom hook to use the /me endpoint
export const useMe = () => {
	return useQuery<UserMe>('me', getMe, {
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
};
