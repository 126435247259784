import { Workplace } from 'core/API/broadcast';
import { GetWorkplaceList } from 'core/API/workplaces';
import { getTokenFromLocalStorage } from 'core/services/axiosInterceptors';
import { useAuth } from 'module/auth/context/AuthContext';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { IWorkplaces } from '../interface/WorkplacesInterface';

interface IRQuery {
	error: null;
	isError: false;
	isIdle: true;
	isLoading: false;
	isLoadingError: false;
	isRefetchError: false;
	isSuccess: false;
	status: 'idle';
	dataUpdatedAt: number;
	errorUpdatedAt: number;
}

interface IQuery extends IRQuery {
	data: IWorkplaces;
	workplaceId: number;
	workplace: Workplace;
	timezone: string;
	isTradeSelected: boolean;
	setIsTradeSelected: (arg0: boolean) => void;
	setWorkplaceIDHandler: (arg0: number, arg1: string) => void;
}

const WorkplacesContext = React.createContext<any>(undefined!);

export function WorkplacesContextProvider({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element {
	const auth = useAuth();

	const [isTradeSelected, setIsTradeSelected] = useState(false);

	const { ...rest } = useQuery(['workplaces'], GetWorkplaceList, {
		enabled: auth.isLoggedin(),
		staleTime: Infinity,
	});

	const { workplaceId, setWorkplaceIDHandler, timezone } = useProviderFunctions();

	const workplace = useMemo(() => {
		if (rest.data) {
			const wp = rest.data.items.find((item) => {
				return item.workplace.id === workplaceId;
			})?.workplace;

			return wp || {};
		}
	}, [rest.data, workplaceId]);

	return (
		<WorkplacesContext.Provider
			value={{
				...rest,
				workplaceId,
				workplace,
				timezone,
				setWorkplaceIDHandler,
				isTradeSelected,
				setIsTradeSelected,
			}}
		>
			{children}
		</WorkplacesContext.Provider>
	);
}

function useProviderFunctions() {
	const [workplaceId, setworkplaceId] = useState<number>(() => {
		const workplaceId = getTokenFromLocalStorage('workplace');
		return parseInt(workplaceId);
	});

	const [timezone, setTimezone] = useState<string>(() => {
		const timezoneLS = getTokenFromLocalStorage('timezone');
		return timezoneLS;
	});

	const setWorkplaceIDHandler = async (id: number, timezone: string) => {
		setTimeout(async () => {
			const data = await JSON.parse(localStorage.getItem('BYOD-app')!);
			data.workplace = +id;
			data.timezone = timezone;
			localStorage.setItem('BYOD-app', JSON.stringify(data));
			setworkplaceId(+id);
			setTimezone(timezone);
		}, 50);
	};

	return { workplaceId, timezone, setWorkplaceIDHandler };
}

export const useWorkplacesContext = (): IQuery => React.useContext(WorkplacesContext);
