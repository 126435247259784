import { Formik, useFormik } from 'formik';
import { useWorkplaceUser } from 'module/documents/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { createContext, useContext, useState } from 'react';

// Create the context
const DocumentContext = createContext(null);

// Create a custom hook for using the DocumentContext
export const useDocumentContext = () => useContext(DocumentContext);

const now = new Date().toISOString().split('T')[0];
// Define the provider component
export const DocumentProvider = ({ children, defaultUser }) => {
	const [selectedEmployee, setSelectedEmployee] = useState(defaultUser);
	const [loadedFormIds, setLoadedFormIds] = useState({});

	const { first_name, last_name, email } = defaultUser;

	const { workplace } = useWorkplacesContext();

	const { data: user } = useWorkplaceUser();

	const isEmployer = user.human_resources;
	console.log('user.human_resources:', isEmployer);

	const initialValues = {
		first_name: isEmployer ? undefined : first_name,
		last_name: isEmployer ? undefined : last_name,
		email: isEmployer ? undefined : email,
		signature_date: now,
		preparer_signature_date: now,
		employer_signature_date: now,
		multiple_jobs_or_spouse_works: false,
		number_of_children: 0,
		number_of_dependents: 0,
		immigration_status: '1',
		head_of_household: false,
		translator_assisted: false,
		employee_rehire: false,
		restaurant_name: workplace.name,
	};

	const formik = useFormik({
		initialValues,
		validate: (_values) => {
			const errors = {};
			// Add your validation logic here
			return errors;
		},
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const resetForm = (newValues) => formik.resetForm({ values: { ...initialValues, ...newValues } });

	return (
		<Formik initialValues={initialValues} onSubmit={console.log}>
			<DocumentContext.Provider
				value={{
					formik,
					resetForm,
					isEmployer,
					selectedEmployee,
					setSelectedEmployee,
					loadedFormIds,
					setLoadedFormIds,
				}}
			>
				{children}
			</DocumentContext.Provider>
		</Formik>
	);
};
