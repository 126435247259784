import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

export const FullLoader: FC = () => {
    return (
        <Box

            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            <CircularProgress as="a" color="secondary" />
        </Box>
    );
}