/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	DocsEmployeeFormDetail,
	DocsEmployeeFormI9,
	fetchEmployeeFormDetails,
	fetchI9EmployeeForms,
	getEmployeeFormW4,
} from 'core/API/documents';
import { isEmpty } from 'lodash';
import { useDocumentContext } from 'module/documents/context';
import {
	dedupItems,
	getUserDetailByEmail,
	removeCommonFormFields,
	sanitizeAPIFormData,
} from 'module/documents/utils';

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CommonGetParams, IPaginatedResponse } from 'types';

export const useEmployeeFormDetails = (params?: CommonGetParams, disabled?: boolean) => {
	return useQuery<IPaginatedResponse<DocsEmployeeFormDetail>>(
		['employee-form/details', params || ''],
		() => fetchEmployeeFormDetails(params),
		{
			staleTime: Infinity, // Ensures the data does not revalidate until the user reloads the page
			cacheTime: Infinity, // Keeps the data in the cache indefinitely
			refetchOnWindowFocus: true, // Prevents refetching when the window is refocused
			enabled: !disabled,
		}
	);
};

//     "id": 5,
//     "identifier": "a9e6d9f8173428f0a4dc3d530",
//     "workplace": [],
//     "employee": {
//         "email": "sam@byod.ai",
//         "first_name": "Sam",
//         "middle_initial": "",
//         "last_name": "Short"
//     },
//     "date_of_birth": "1979-12-01T00:00:00+00:00",
//     "phone": "9178462096",
//     "address": "13609 COTESWORTH CT",
//     "city": "HUNTERSVILLE",
//     "state": "NC",
//     "zip_code": "28078-5661",
//     "ssn": "5555555555",
//     "drivers_license_number": "5454545454",
//     "author": {
//         "email": "sam@byod.ai",
//         "first_name": "Sam",
//         "middle_initial": "",
//         "last_name": "Short"
//     },
//     "employee_signature_file": {
//         "id": 12,
//         "name": "signature.png",
//         "path": "/uploads/employee-form-signature/66cf232f2cee3_66cf232f2cf09.png",
//         "extension": "png",
//         "size": "4566",
//         "mime_type": "image/png"
//     }
// }

export const pruneI9Data = (param: DocsEmployeeFormI9) => {
	const {
		employee,
		workplace,
		id,
		identifier,
		author,
		employee_form_i9_supplement,
		employee_form_i9_supplement_translator,
		...rest
	} = param;
	return {
		...rest,
		...employee,
	};
};

export const useEmployeeFormData = (isHr: boolean, params?: CommonGetParams) => {
	const [isLoading, setIsLoading] = useState(false);
	const { selectedEmployee, resetForm, setLoadedFormIds } = useDocumentContext();

	const email = selectedEmployee?.user.email;

	useEffect(() => {
		if (!isHr) return;
		if (!email) return;

		setIsLoading(true);

		Promise.all([
			fetchEmployeeFormDetails(params),
			fetchI9EmployeeForms(params),
			getEmployeeFormW4(params),
		]).then((data) => {
			const [employeeFormDetails, i9FormData, w4FormData] = data;

			const loadedFormIds: Record<string, number> = {};

			const activeUserDetails = (() => {
				const items = dedupItems(employeeFormDetails?.items || []);
				const activeUserDetails = getUserDetailByEmail(items, email) || {};

				// use does not have existing form
				if (isEmpty(activeUserDetails)) return {};

				const prunedDetails = removeCommonFormFields(activeUserDetails);

				loadedFormIds.employeeFormId = activeUserDetails?.id;
				return {
					...prunedDetails,
					employee_signature: activeUserDetails?.employee_signature_file?.id,
					internal_signature_file: activeUserDetails?.employee_signature_file?.path,
				};
			})();

			const activeI9FormData = (() => {
				const items = dedupItems(i9FormData?.items || []);
				const activeUserDetails = getUserDetailByEmail(items, email) || undefined;

				// use does not have existing form
				if (isEmpty(activeUserDetails)) return {};

				const prunedDetails = removeCommonFormFields(activeUserDetails);

				loadedFormIds.i9FormId = activeUserDetails.id;
				return {
					...prunedDetails,
					employee_form_i9_supplement: null,
					employee_form_i9_supplement_translator: null,
				};
			})();

			const activeW4FormData = (() => {
				const items = dedupItems(w4FormData?.items || []);
				const activeUserDetails = getUserDetailByEmail(items, email) || undefined;
				console.log('activeUserDetails:', activeUserDetails);
				// use does not have existing form
				if (isEmpty(activeUserDetails)) return {};
				const prunedDetails = removeCommonFormFields(activeUserDetails);
				console.log('prunedDetails:', prunedDetails);

				loadedFormIds.w4FormId = activeUserDetails.id;
				return {
					...prunedDetails,
				};
			})();

			setLoadedFormIds(loadedFormIds);

			resetForm(
				sanitizeAPIFormData({ ...activeUserDetails, ...activeI9FormData, ...activeW4FormData })
			);
		});

		setIsLoading(false);
	}, [email, params, isHr]);

	if (!isHr) {
		setIsLoading(false);
		return {};
	}

	return { isLoading };
};
