import {
	Eventcalendar,
	MbscCalendarEvent,
	MbscResource,
	momentTimezone,
	setOptions,
} from '@mobiscroll/react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { fetchAllEventTypes } from 'core/API/events';
import { GetUsersList } from 'core/API/users';
// CSS modules
import Loading from 'core/components/Loading/Loading';
import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { IWorkplaces } from 'module/workplaces/interface/WorkplacesInterface';

import { useCalendarView } from 'core/hooks/useCalendarView';
import { CalendarHeader, ScheduleEvent, ScheduleResource } from './DesktopPage.component';
import './DesktopPage.style.scss';
import { debounceFunction } from 'core/hooks/useDecbouncedValue';

// setup Mobiscroll Moment plugin
momentTimezone.moment = momentTZ;

interface IDesktopPage {
	openEditShiftModal: (args: any) => void;
	openAddShiftModal: (args: any) => void;
	allSchedules: MbscCalendarEvent[];
	allEvents: MbscCalendarEvent[];
	workplacesData: IWorkplaces;
	updateTimeRange: (firstday: Date, lastday: Date) => void;
	setCalendarType: (args: 'schedule' | 'request' | 'event') => void;
	calendarType: string;
	openRequestsModal: (args: any) => void;
	openAddEventModal: (args: any) => void;
	openEditEventModal: (args: any) => void;
	reassignShiftToAnotherUser: (newShift: MbscCalendarEvent) => void;
	refetch: any;
	updateOneShiftHandler: (selectedEvent: MbscCalendarEvent) => void;
}

setOptions({
	theme: 'ios',
	themeVariant: 'light',
});

const DesktopPage: FC<IDesktopPage> = ({
	openEditShiftModal,
	openAddShiftModal,
	allSchedules,
	allEvents,
	workplacesData,
	updateTimeRange,
	setCalendarType,
	calendarType,
	openRequestsModal,
	openAddEventModal,
	openEditEventModal,
	reassignShiftToAnotherUser,
	refetch,
	updateOneShiftHandler,
}) => {
	const { workplaceId, timezone } = useWorkplacesContext();
	const { calendarView, calendarConfig } = useCalendarView();

	const auth = useAuth();

	const [invalidDates, setInvalidDates] = useState<any>();

	const {
		data: usersData,
		isLoading: isLoadingUsersWorkplace,
		refetch: refetchUsers,
	} = useQuery(['users', workplaceId], () => GetUsersList(workplaceId), {
		enabled: calendarType === 'schedule' || calendarType === 'request',
		select: (data) => {
			function filterAndPreserveDuplicates(users) {
				const uniqueIds = new Set();
				const duplicates = [];

				const uniques = users.filter((user) => {
					if (uniqueIds.has(user.user.id)) {
						debounceFunction(() => {
							console.group('Duplicate user!');
							console.log('Found duplicate user: ', user);
							console.log(
								'Original user: ',
								users.find((u) => u.user.id === user.user.id)
							);
							console.groupEnd();
						}, 1000);

						duplicates.push(user); // Add duplicate to duplicates array
						return false; // If ID is duplicate, filter it out
					} else {
						uniqueIds.add(user.user.id); // Add ID to uniqueIds Set
						return true; // Keep the user
					}
				});

				return { uniques, duplicates };
			}

			const { uniques } = filterAndPreserveDuplicates(data);

			return uniques;
		},
	});

	const { data: eventTypes } = useQuery(['eventTypes'], () => fetchAllEventTypes(), {
		enabled: calendarType === 'event',
	});

	useEffect(() => {
		if (allSchedules.length) {
			const tempArrayOfInvalidDates = [];
			allSchedules.forEach((shift) => {
				if (shift?.shift_type?.id === 2 && shift?.scheduled) {
					tempArrayOfInvalidDates.push({
						start: moment(shift.start).toDate(),
						end: moment(shift.end).toDate(),
						title: 'Time off',
						resource: shift.resource,
					});
				}
			});
			setInvalidDates(tempArrayOfInvalidDates);
			// Removed but might be required:
			// refetchUsers();
		}
	}, [allSchedules, refetchUsers]);

	const myResources = useMemo<MbscResource[]>(() => {
		const usersListResource: MbscResource[] = [];
		const firstUser: MbscResource[] = [];
		usersData?.forEach(
			(value) => {
				if (value.user.id === auth.user.id) {
					firstUser.push({
						id: value.user.id,
						name: `${value.user.first_name} ${value.user.last_name}`,
						role: value.role,
						active_week_hours: value.active_week_hours,
					});
				} else {
					usersListResource.push({
						id: value.user.id,
						name: `${value.user.first_name} ${value.user.last_name}`,
						role: value.role,
						active_week_hours: value.active_week_hours,
					});
				}
			},
			[usersData]
		);

		// sort users by their shifts
		usersListResource.sort((a, b) => {
			let userAshift = null;
			let userBshift = null;

			allSchedules.forEach((shift) => {
				if (shift.resource === a.id) {
					userAshift = shift;
				} else if (shift.resource === b.id) {
					userBshift = shift;
				}
			});

			if (userAshift !== null && userBshift !== null) {
				const firstShiftStart = new Date(userAshift.start).getTime();
				const secondShiftStart = new Date(userBshift.start).getTime();

				const titleA = userAshift.title.split(' ');
				const titleB = userBshift.title.split(' ');

				if (userAshift?.role && userBshift?.role) {
					return (
						userAshift?.role.localeCompare(userBshift?.role) ||
						firstShiftStart - secondShiftStart ||
						titleB[titleB.length - 1].localeCompare(titleA[titleA.length - 1])
					);
				}

				return (
					firstShiftStart - secondShiftStart ||
					titleB[titleB.length - 1].localeCompare(titleA[titleA.length - 1])
				);
			}

			// if last user does not have shift push them to the end
			if (userBshift === null) {
				return -1;
			}

			return 0;
		});

		// sort by roles

		return [...firstUser, ...usersListResource]!;
	}, [usersData, allSchedules, auth.user.id]);

	const eventResources = useMemo<MbscResource[]>(() => {
		const eventsListResource = eventTypes?.items?.map((eventType: any) => {
			return {
				id: eventType.id,
				name: eventType.type,
			};
		});

		return eventsListResource!;
	}, [eventTypes]);

	const compareEvents = (selectedEvent: MbscCalendarEvent) => {
		allSchedules.forEach((schedule) => {
			if (schedule.id === selectedEvent.id) {
				if (selectedEvent.resource !== schedule.resource) {
					// check for roles of the other user and update shift

					reassignShiftToAnotherUser(selectedEvent);
				} else {
					// open modal for update shift
					// openEditShiftModal(selectedEvent);
					// call function to update shift
					updateOneShiftHandler(selectedEvent);
				}
			}
		});
	};

	const disableDeleteKey = () => {
		refetch();
	};

	return isLoadingUsersWorkplace ? (
		<Loading />
	) : (
		<Eventcalendar
			invalid={invalidDates}
			invalidateEvent="strict"
			theme="ios"
			/* width={'82vw'} */
			dataTimezone={timezone}
			displayTimezone={timezone}
			timezonePlugin={momentTimezone}
			themeVariant="light"
			clickToCreate={true}
			// dragToCreate={calendarType !== 'request'}
			dragToCreate={false}
			dragToMove={true}
			dragToResize={calendarView !== 'week'} //disable resize shift only on week view
			view={calendarConfig}
			data={calendarType === 'event' ? allEvents : allSchedules}
			renderScheduleEvent={(event) => <ScheduleEvent calendarType={calendarType} data={event} />}
			renderResource={(resource) => (
				<ScheduleResource resource={resource} calendarType={calendarType} />
			)}
			// invalid={myInvalids}
			resources={calendarType === 'event' ? eventResources : myResources}
			renderHeader={() => (
				<CalendarHeader
					calendarType={calendarType}
					setCalendarType={setCalendarType}
					workplacesData={workplacesData}
					workplaceId={workplaceId}
				/>
			)}
			dragTimeStep={30}
			cssClass="md-switching-timeline-view-cont md-timeline-height"
			timeFormat="HH:mm"
			externalDrop={true}
			// eventOrder={orderMyEvents}
			onCellClick={(event) => {
				switch (calendarType) {
					case 'event':
						openAddEventModal(event);
						break;
					default:
						openAddShiftModal(event);
						break;
				}
			}}
			onEventDoubleClick={({ event }) => {
				/* Logic for event double click */
				if (event.role || !event.scheduled) {
					// if no role its timeoff disable popup on timeoff
					switch (calendarType) {
						case 'request':
							openRequestsModal(event);
							break;
						case 'event':
							openEditEventModal({
								...event,
								end: new Date(event.end ? event.end.toString().split('+')[0] : '').toString(),
								start: new Date(event.start ? event.start.toString().split('+')[0] : '').toString(),
							});
							break;
						default:
							openEditShiftModal(event);
							break;
					}
				}
			}}
			onEventUpdate={({ event }) => {
				/* Logic for event update */
				switch (calendarType) {
					case 'request':
						openRequestsModal(event);
						break;
					case 'event':
						openEditEventModal({
							...event,
							fromUtc: moment(event?.start).format('HH:mm'),
							toUtc: moment(event?.end).format('HH:mm'),
						});
						break;
					default:
						compareEvents(event);
						break;
				}
			}}
			onPageChange={(event) => {
				/* Your custom event handler goes here */
				// setFromDate(formatDate(event.firstDay));
				// setToDate(formatDate(event.lastDay));
				updateTimeRange(event.firstDay, event.lastDay);
			}}
			onEventCreate={(event) => {
				if (event.action === 'drag') return;

				if (event.action === 'externalDrop') {
					openEditShiftModal(event.event);
				} else {
					openAddShiftModal(event);
				}
			}}
			onEventClick={() => {
				disableDeleteKey();
			}}
		/>
	);
};

export default DesktopPage;
