import { MbscEventcalendarView } from '@mobiscroll/react';
import useQueryParams from './useQueryParams';

type CalendarView = 'week' | 'day' | 'workweek';

export const getCalendarConfig = (view: CalendarView): MbscEventcalendarView => {
	switch (view) {
		case 'day': {
			return {
				timeline: {
					type: 'day',
					timeCellStep: 60,
					timeLabelStep: 60,
				},
			};
		}
		case 'workweek': {
			return {
				timeline: {
					type: 'week',
					eventList: true,
					startDay: 1,
					endDay: 5,
				},
			};
		}
		case 'week':
		default: {
			return {
				timeline: {
					type: 'week',
					eventList: true,
					startDay: 1,
					endDay: 0,
					timeCellStep: 1440,
					timeLabelStep: 1440,
				},
			};
		}
	}
};

export const useCalendarView = () => {
	const { query, change } = useQueryParams();

	const calendarView = (query.time || 'day') as CalendarView;

	const setCalendarView = (view: CalendarView) => {
		change({ time: view });
	};

	const calendarConfig = getCalendarConfig(calendarView);

	return { calendarView, calendarConfig, setCalendarView };
};
