import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from 'module/auth/context/AuthContext';
import { AccountCircle } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'module/navigation/enums/routes.enums';

export default function NavigationMenu() {
	const auth = useAuth();
	const { push, location } = useHistory();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id="basic-button"
				startIcon={<AccountCircle style={{ fontSize: '2rem' }} color="secondary" />}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{auth?.user?.first_name} {auth?.user?.last_name}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					onClick={() =>
						push({
							pathname: ROUTES.LOGIN,
							state: location.pathname,
						})
					}
				>
					Switch workplace
				</MenuItem>
				<MenuItem onClick={auth.logout}>Logout</MenuItem>
			</Menu>
		</div>
	);
}
