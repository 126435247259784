import { apiService } from 'core/services/apiService';
import { CommonGetParams, IPaginatedResponse } from 'types';

export const uploadSignatureImage = async (file: File) => {
	const formData = new FormData();
	formData.append('image', file);

	const response = await apiService.postUpload('/employee-form/signiture/upload', formData);

	return response.data;
};

export interface EmployeeFormDetailsPayload {
	employee: { id: number };
	date_of_birth: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	ssn: string;
	drivers_license_number: string;
	author: { id: number };
	employee_signature_file: { id: number };
}

export interface I9FormPayload {
	employee: {
		id: number;
	};
	hr?: {
		id: number;
	};
	workplace: {
		id: number;
	};
	immigration_status: number;
	uscis_a_number?: string;
	i94_admission_number?: string;
	foreign_passport_number?: string;
	authorization_expiration_date?: string;
	translator_assisted?: boolean;
	employee_rehire?: boolean;
	signature_approved: boolean;

	// Non-Citizen Document Fields
	employee_form_i9_non_citizen_a?: { id: number };
	employee_form_i9_non_citizen_b?: { id: number };
	employee_form_i9_non_citizen_c?: { id: number };
}

export interface I9SupplementAPayload {
	employee_form_i9: {
		id: number;
	};
	employee: {
		id: number;
	};
	rehire_date: string;
	document_title: string;
	document_number: string;
	expiration_date: string;
	employer_title: string;
	employer_business_name: string;
	employer_business_address: string;
	employer_city: string;
	employer_state: string;
	employer_zip_code: string;
	signature_approved: boolean;
}

// Define the payload interface
export interface W4FormPayload {
	employee: {
		id: number;
	};
	multiple_jobs_or_spouse_works: boolean;
	filing_status: boolean;
	number_of_children: number;
	number_of_dependents: number;
	other_income: number;
	deductions: number;
	extra_withholding: number;
	signature_approved: boolean;
}

export interface I9SupplementTranslatorPayload {
	employee_form_i9: {
		id: number;
	};
	first_name: string;
	middle_initial: string;
	last_name: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	signature_approved?: boolean;
}

export interface W4Supplement2BPayload {
	employee_form_w4: {
		id: number;
	};
	employee: {
		id: number;
	};
	two_jobs_amount: number;
	three_jobs_line2_a: number;
	three_jobs_line2_b: number;
	three_jobs_line2_c: number;
	pay_periods_per_year: number;
	amount_step4_c: number;
	signature_approved: boolean;
}

export interface FileUploadResponse {
	id: number;
	name: string;
	path: string;
	extension: string;
	size: string;
	mime_type: string;
}

export const fileUpload = async (file: any): Promise<FileUploadResponse> => {
	const { ...data } = apiService.responseHandler(
		await apiService.postUpload(`employee-form/file/upload`, file)
	);

	return data;
};

export interface I9NonCitizenPayload {
	title: string;
	authority: string;
	number: string;
	expiration_date: string;
	employee_file: {
		id: number;
	};
}
export interface I9NonCitizenResponse {
	id: number;
	identifier: string;
	title: string;
	authority: string;
	number: string;
	expiration_date: string;
	employee_file: FileUploadResponse;
	synced: true;
}

export const submitI9NonCitizen = async (
	payload: I9NonCitizenPayload
): Promise<I9NonCitizenResponse> => {
	const { ...data } = apiService.responseHandler(
		await apiService.postUpload(`employee-form/i9/non-citizen`, payload)
	);

	return data;
};

// **************************** POST FUNCTIONS ***************************

export const submitEmployeeFormDetails = async (
	payload: EmployeeFormDetailsPayload,
	workplaceId: number
) => {
	const response = await apiService.post('/employee-form/details', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

export const submitI9FormDetails = async (payload: I9FormPayload, workplaceId: number) => {
	const response = await apiService.post('/employee-form/i9', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

export const submitI9SupplementADetails = async (payload: I9SupplementAPayload) => {
	const response = await apiService.post('/employee-form/i9/supplement', payload);
	return response.data;
};

// API function to submit W4 form details
export const submitW4FormDetails = async (payload: W4FormPayload, workplaceId: number) => {
	const response = await apiService.post('/employee-form/w4', {
		...payload,
		workplace: { id: workplaceId },
	});

	return response.data;
};

// API function to submit I-9 Supplement Translator details
export const submitI9SupplementTranslator = async (payload: I9SupplementTranslatorPayload) => {
	const response = await apiService.post('/employee-form/i9/supplement/translator', payload);
	return response.data;
};

export const submitW4Supplement2B = async (payload: W4Supplement2BPayload) => {
	const response = await apiService.post('/employee-form/w4/supplement/2b', payload);
	return response.data;
};

// **************************** GET FUNCTIONS ***************************

export interface CommonFormResponseFields {
	id: number;
	identifier?: string;
	workplace?: DocsWorkplace;
	employee?: DocsEmployee;
	author?: DocsAuthor;
}

export interface CommonFormRequestFields {
	workplace: { id: number };
	employee: { id: number };
	signature_approved: boolean;
	hr: { id: number };
}

export interface DocsWorkplace {
	id: string;
}

export interface DocsEmployee {
	email?: string;
	first_name?: string;
	middle_initial?: string;
	last_name?: string;
}

export interface DocsAuthor {
	email?: string;
	first_name?: string;
	middle_initial?: string;
	last_name?: string;
}

export interface DocsEmployeeSignatureFile {
	id: number;
	name: string;
	path: string;
	extension: string;
	size: string;
	mime_type: string;
}

export interface DocsEmployeeFormDetail extends CommonFormResponseFields {
	date_of_birth?: string;
	phone?: string;
	address?: string;
	city?: string;
	state?: string;
	zip_code?: string;
	ssn?: string;
	drivers_license_number?: string;
	employee_signature_file?: DocsEmployeeSignatureFile;
}

// ****

export interface DocsEmployeeFormI9 extends CommonFormResponseFields {
	immigration_status: number;
	uscis_a_number?: string;
	i94_admission_number?: string;
	foreign_passport_number?: string;
	authorization_expiration_date?: string;
	translator_assisted?: boolean;
	employee_rehire?: boolean;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
	employee_form_i9_supplement?: DocsEmployeeFormI9Supplement[];
	employee_form_i9_supplement_translator?: DocsEmployeeFormI9SupplementTranslator[];
	hr?: DocsHR;
}

export interface DocsEmployeeFormI9Supplement {
	identifier: string;
	rehire_date?: string;
	document_title: string;
	document_number: string;
	expiration_date?: string;
	employer_title: string;
	employer_business_name: string;
	employer_business_address: string;
	employer_city: string;
	employer_state: string;
	employer_zip_code: string;
	author: DocsAuthor;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export interface DocsEmployeeFormI9SupplementTranslator {
	identifier: string;
	first_name: string;
	middle_initial?: string;
	last_name: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	author: DocsAuthor;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export interface DocsHR {
	identifier: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	zip_code: string;
	country: string;
	employer_ein: string;
	first_date_employment: string;
	signature_approved: boolean;
	signature_date: string;
	author: DocsAuthor;
	synced?: boolean;
}

// *****

export interface DocsEmployeeFormW4 extends CommonFormResponseFields {
	multiple_jobs_or_spouse_works: boolean;
	filing_status: boolean;
	number_of_children: number;
	number_of_dependents: number;
	other_income: number;
	deductions: number;
	extra_withholding: number;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
	employee_form_w4_supplement2_b?: DocsEmployeeFormW4Supplement2B[];
	hr?: DocsHR;
}

export interface DocsEmployeeFormW4Supplement2B extends Partial<CommonFormResponseFields> {
	identifier: string;
	two_jobs_amount: number;
	three_jobs_line2_a: number;
	three_jobs_line2_b: number;
	three_jobs_line2_c: number;
	pay_periods_per_year: number;
	amount_step4_c: number;
	signature_approved: boolean;
	signature_date: string;
	synced?: boolean;
}

export const fetchEmployeeFormDetails = async (
	params: CommonGetParams = {}
): Promise<IPaginatedResponse<DocsEmployeeFormDetail>> => {
	let response;
	try {
		response = await apiService.get('/employee-form/details', {
			params: {
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 500,
				page: params.page || 1,
			},
		});
		return response.data as IPaginatedResponse<DocsEmployeeFormDetail>;
	} catch (error) {
		console.log(error);
	}
};

export const fetchI9EmployeeForms = async (
	params: CommonGetParams = {}
): Promise<IPaginatedResponse<DocsEmployeeFormI9>> => {
	let response;
	try {
		response = await apiService.get('/employee-form/i9', {
			params: {
				query: params.query || '',
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 500,
				page: params.page || 1,
			},
		});
		return response.data as IPaginatedResponse<DocsEmployeeFormI9>;
	} catch (error) {
		console.log(error);
	}
};

export const fetchEmployeeFormW4s = async (
	params: CommonGetParams = {}
): Promise<IPaginatedResponse<DocsEmployeeFormW4>> => {
	let response;
	try {
		response = await apiService.get('/employee-form/w4', {
			params: {
				orderBy: params.orderBy || 'id',
				direction: params.direction || 'ASC',
				limit: params.limit || 5,
				page: params.page || 1,
			},
		});

		return response.data as IPaginatedResponse<DocsEmployeeFormW4>;
	} catch (error) {
		console.log(error);
	}
};

// **************************** PUT FUNCTIONS ***************************

export interface UpdateI9FormPayload extends CommonFormRequestFields {
	immigration_status: number;
	uscis_a_number?: string;
	i94_admission_number?: string;
	foreign_passport_number?: string;
	authorization_expiration_date?: string;
	translator_assisted?: boolean;
	employee_rehire?: boolean;
	signature_approved: boolean;
}

export interface UpdateW4FormPayload extends CommonFormRequestFields {
	multiple_jobs_or_spouse_works: boolean;
	filing_status: boolean;
	number_of_children: number;
	number_of_dependents: number;
	other_income: number;
	deductions: number;
	extra_withholding: number;
	signature_approved: boolean;
}

// Define the API function
export const updateI9Form = async (formId: string, payload: UpdateI9FormPayload): Promise<any> => {
	const response = await apiService.put(`/employee-form/i9/${formId}`, payload);
	return response.data;
};

export const updateW4Form = async (formId: string, payload: UpdateW4FormPayload): Promise<any> => {
	const response = await apiService.put(`/employee-form/w4/${formId}`, payload);
	return response.data;
};

export const getEmployeeFormW4 = async (
	params: CommonGetParams = {}
): Promise<IPaginatedResponse<DocsEmployeeFormW4>> => {
	const response = await apiService.get('/employee-form/w4', {
		params: {
			orderBy: params.orderBy || 'id',
			direction: params.direction || 'ASC',
			limit: params.limit || 5,
			page: params.page || 1,
		},
	});

	return response.data as IPaginatedResponse<DocsEmployeeFormW4>;
};
